import { Col, Row, Tooltip } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import { IHeaderProps } from './IHeaderProps';

import './Header.scss';

const Header = (props: IHeaderProps) => {
    const {
		userName,
		userLogo,
        logOut
	} = props;

    return (
        <Row align="middle" justify="space-between" className="header">
            <Col>
                <img alt="logo" className='logo' src={userLogo} onClick={() => window.open('https://qualitasfunds.com/', '_blank')} />
            </Col>
            
            <Col className='userName'>
                Hola, {`${userName}`} &nbsp;
                <Tooltip title="Cerrar Sesión">
                    <LogoutOutlined onClick={logOut}/>
                </Tooltip>
            </Col>
        </Row>
    );
};

export default Header;
