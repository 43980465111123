import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Divider } from 'antd';

import Login, { PrivateRoute, AuthAPI, useAuth } from '@qualitas/auth';
import AnalysisAndReporting, { useAnalysisAndReporting, AnalysisAndReportingAPI } from '@qualitas/reporting';
import FeedbackModal, { useFeedbackModal } from '@qualitas/feedback-modal';

import Header from './Components/Header/Header';

import './App.scss';
import '../node_modules/@qualitas/auth/dist/Auth.scss';
import '../node_modules/@qualitas/reporting/dist/AnalysisAndReporting.scss';

const App = () => {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_NEW_WEB_API;

	const authAPI = AuthAPI(baseUrl);
	const authProps = useAuth({
		api: authAPI,
		navigate,
		redirectUrl: '/HomePowerBI',
		idSystemMaster: 3,
		systemMasterName: "PowerBIReporting",
		redirectPassResetUrl: process.env['REACT_APP_Pass_Reset']
	});

	const feedbackModalProps = useFeedbackModal({});

	const api = AnalysisAndReportingAPI({
		baseUrl,
		getAuthToken: authProps.getAuthToken,
		showServiceFeedbackModal: feedbackModalProps.showServiceFeedbackModal
	});
	
	const reportingProps = useAnalysisAndReporting({ api, hasMenu: true, idInstitution: authProps.userIdInstitution });

	return (
		<Routes>
			<Route element={<PrivateRoute validateAuthToken={authProps.validateAuthToken}/>}>
				<Route path="/HomePowerBI" element={
					<>
						<Header
							userName={authProps.userName}
							userLogo={authProps.userLogo}
							logOut={authProps.logOut}
						/>
						<Divider className="header-divider"/>
						<AnalysisAndReporting
							{...reportingProps}
							hasMenu
						>
							<FeedbackModal {...feedbackModalProps}/>
						</AnalysisAndReporting>
					</>
				}/>
				<Route path="/login" element={<Login {...authProps} appName="Power BI Reporting"/>}/>
			</Route>
			<Route path="*" element={<Navigate to="/HomePowerBI"/>}/>
		</Routes>
	);
}

export default App;
